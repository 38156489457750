import { ContentRewardCampaignForCarousel } from "../../../../../types/misc";

import { LongCardGrid } from "../components/CardGrid";
import { PreviewHeader } from "../components/Header";
import ContentCard from "./components/ContentCard";
import { useFetchContent } from "./data";
import PreviewWidgetWrapper from "../components/PreviewWidgetWrapper";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import colors from "../../../../styles/colors";
import { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import PreviewContainer from "../components/PreviewContainer";
import { defaultPreviewLazyLoadConfig } from "../../../../../utils/lazyLoadConfig";
import { useLinkPrefix } from "../../../../../utils/hooks/useLinkPrefix";
import { isWhite } from "../../../../../utils/StringUtils";

const ContentPreview = ({
  brandclubIds,
  backgroundColor = colors.blueWhite,
  arrowColor,
  subtitle,
  title,
  className,
  pageSize = 4,
}: {
  brandclubIds?: string[];
  backgroundColor?: string;
  arrowColor?: "blue" | "grey" | null;
  subtitle?: string | null;
  title?: string;
  className?: string;
  pageSize?: number;
}) => {
  const ref = useRef(null);
  const widgetInView = useInView(ref, defaultPreviewLazyLoadConfig);

  const { linkPrefix } = useLinkPrefix();

  const { data, loading, fetchInit, initialFetchTriggered } = useFetchContent({
    pageSize,
    autoInitFetch: false,
    brandclubIds,
  });

  useEffect(() => {
    if (widgetInView) {
      fetchInit();
    }
  }, [widgetInView, fetchInit]);

  if (initialFetchTriggered && !loading && data.length === 0) {
    return null;
  }

  return (
    <PreviewWidgetWrapper
      ref={ref}
      backgroundColor={backgroundColor}
      className={className}
    >
      <PreviewContainer className="preview-container--content">
        {!initialFetchTriggered || (loading && !data.length) ? (
          <StoreLoadingSkeleton
            cardStyle="longCard"
            header
            row={2}
            bgColor={isWhite(backgroundColor) ? "white" : "grey"}
          />
        ) : (
          <>
            <PreviewHeader
              title={title ?? "Content rewards"}
              linkTo={linkPrefix + "/contentrewards"}
              subtitle={
                subtitle === null
                  ? undefined
                  : "Earn rewards for viewing content"
              }
              indicator={arrowColor === null ? undefined : arrowColor || "blue"}
            />

            <LongCardGrid>
              {data
                .slice(0, pageSize)
                .map((d: ContentRewardCampaignForCarousel) => (
                  <ContentCard
                    key={d.campaignId}
                    content={d}
                    brandName={d.brandclub?.name || ""}
                    border={isWhite(backgroundColor)}
                  ></ContentCard>
                ))}
            </LongCardGrid>
          </>
        )}
      </PreviewContainer>
    </PreviewWidgetWrapper>
  );
};

export default ContentPreview;
