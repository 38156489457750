import { Skeleton, SkeletonProps, useMediaQuery } from "@mui/material";
import {
  CardGrid,
  LongCardGrid,
  CardGridForThree,
  LongCardList,
} from "../pages/AppPages/Pages/components/CardGrid";
import { MOBILE_SCREEN_SIZE } from "../AppNavigation/constants";

const LONGCARD_HEIGHT = 165.75;
const CARD_HEIGHT = 400;

const LONGCARD_HEIGHT_MOBILE = 105.75;
const CARD_HEIGHT_MOBILE = 280;

type CardTypes = "card" | "longCard" | "cardForThree" | "longCardList";

const getCardComponent = (cardStyle?: CardTypes) => {
  switch (cardStyle) {
    case "card":
      return CardGrid;
    case "longCard":
      return LongCardGrid;
    case "cardForThree":
      return CardGridForThree;
    case "longCardList":
      return LongCardList;
    default:
      return LongCardList;
  }
};

const StoreLoadingSkeleton = ({
  header,
  row = 1,
  cardStyle = "card",
  bgColor = "white",
  skeletonCardProps,
  testId,
}: {
  header: boolean;
  row?: number;
  cardStyle?: CardTypes;
  bgColor?: "white" | "grey";
  skeletonCardProps?: SkeletonProps;
  testId?: string;
}) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const CardContainer = getCardComponent(cardStyle);

  const colorStyle = bgColor === "white" ? "#f6f8fa" : "#e6ebf0";
  return (
    <>
      {header ? (
        <div data-testid={`${testId}-header`}>
          <Skeleton
            variant="rectangular"
            width={177}
            height={35.56}
            style={{ borderRadius: 6, marginBottom: 10 }}
            sx={{ bgcolor: colorStyle }}
          />
          <Skeleton
            variant="rectangular"
            width={233}
            height={23.89}
            style={{ borderRadius: 6, marginBottom: 37 }}
            sx={{ bgcolor: colorStyle }}
          />
        </div>
      ) : null}
      <CardContainer
        data-testid={`${testId}-card-container`}
        style={{
          gridTemplateRows: `repeat(${row}, 1fr)`,
          gridAutoRows: "0",
          overflowY: "hidden",
          gridRowGap: 0,
          gridColumnGap: 20,
        }}
      >
        {Array.from(Array(10).keys()).map((i, idx) => {
          return (
            <Skeleton
              key={idx}
              variant="rectangular"
              width={"100%"}
              height={
                ["longCard", "longCardList"].includes(cardStyle)
                  ? mobileView
                    ? LONGCARD_HEIGHT_MOBILE
                    : LONGCARD_HEIGHT
                  : mobileView
                  ? CARD_HEIGHT_MOBILE
                  : CARD_HEIGHT
              }
              {...skeletonCardProps}
              style={{
                borderRadius: 12,
                marginBottom: 20,
                ...skeletonCardProps?.style,
              }}
              sx={{ bgcolor: colorStyle, ...skeletonCardProps?.sx }}
            />
          );
        })}
      </CardContainer>
    </>
  );
};

export default StoreLoadingSkeleton;
