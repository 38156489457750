import Branch from "branch-sdk";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { BranchSessionData } from "@brandclub/common-ui";

const useBranchIOSession = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const [branchSessionData, setBranchSessionData] =
    useState<Branch.SessionData | null>(null);

  useEffect(() => {
    const fetchBranchSessionData = async () => {
      const apiKey = appConfig?.brandAppConfig?.branchIOConfig?.key;
      if (!apiKey) {
        return;
      }

      try {
        const data = await BranchSessionData({ branchKey: apiKey });
        setBranchSessionData(data);
      } catch (error) {
        console.error("Error fetching Branch session data:", error);
      }
    };

    fetchBranchSessionData();
  }, [appConfig]);

  return branchSessionData;
};

export default useBranchIOSession;
