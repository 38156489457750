import { useCallback, useEffect, useState } from "react";
import {
  getSurveysForUserV2,
  getUnauthedSurveysForUser,
} from "../../../../../api";
import { useSignedIn } from "@/utils/hooks/useSignedIn";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import { SurveyPageResponse } from "@/redux/apolloCache";

interface SurveysForUserResponse {
  SurveysForUserV2: SurveyPageResponse;
}

interface UnauthedSurveysForUserResponse {
  UnauthedSurveysForUser: SurveyPageResponse;
}

// Use a type that combines both possible responses for type safety
type QueryResponse = Partial<
  SurveysForUserResponse & UnauthedSurveysForUserResponse
>;

// Define variables type
interface SurveyQueryVariables {
  brandclubIds?: string[];
  includedCampaignIds?: string[];
  page: {
    start: number;
    size: number;
  };
}

interface UseFetchSurveysProps {
  /**
   * Whether to fetch the surveys automatically when the component mounts
   */
  autoInitFetch?: boolean;
  /**
   * The brandclub ids to include in the query
   */
  brandclubIds?: string[];
  /**
   * The campaign ids to include in the query
   */
  includedCampaignIds?: string[];
  /**
   * The size of the page to fetch
   */
  pageSize?: number;
}

export const useFetchSurveys = ({
  autoInitFetch = true,
  brandclubIds,
  includedCampaignIds,
  pageSize = 25,
}: UseFetchSurveysProps) => {
  const { signedIn } = useSignedIn();
  const query = signedIn ? getSurveysForUserV2 : getUnauthedSurveysForUser;
  const [initialFetchTriggered, setInitialFetchTriggered] = useState(false);

  // Using useLazyQuery instead of useQuery with skip option
  // This ensures reliable re-rendering when data is fetched with autoInitFetch=false
  const [
    queryFn,
    { data: queryData, loading, error, fetchMore, networkStatus },
  ] = useLazyQuery<QueryResponse, SurveyQueryVariables>(query, {
    variables: {
      brandclubIds,
      includedCampaignIds,
      page: {
        start: 0,
        size: pageSize,
      },
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const fetchInit = useCallback(() => {
    if (initialFetchTriggered) {
      return;
    }
    setInitialFetchTriggered(true);
    queryFn();
  }, [queryFn, initialFetchTriggered]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  const handleFetchMore = useCallback(() => {
    if (!queryData) return Promise.resolve();

    // Get the correct data based on the signed-in state
    const responseData = signedIn
      ? queryData.SurveysForUserV2
      : queryData.UnauthedSurveysForUser;

    if (!responseData || !responseData.hasMore) {
      return Promise.resolve();
    }

    // Let Apollo's cache handle merging based on the typePolicies
    return fetchMore({
      variables: {
        page: {
          start: responseData.nextPage?.start || 0,
          size: pageSize,
        },
      },
      // No updateQuery needed - Apollo cache will handle the merge
    });
  }, [fetchMore, queryData, signedIn, pageSize]);

  // Get data based on signed-in state
  const responseData = queryData
    ? signedIn
      ? queryData.SurveysForUserV2
      : queryData.UnauthedSurveysForUser
    : null;

  const data = responseData?.items || [];
  const hasMore = responseData?.hasMore || false;
  const loadingMore = loading && networkStatus === NetworkStatus.fetchMore;

  return {
    data,
    loading,
    error: error ? error.message : null,
    hasMore,
    fetchMore: handleFetchMore,
    fetchInit,
    loadingMore,
    initialFetchTriggered,
  };
};
