import { useCallback, useEffect, useState } from "react";

import {
  getReviewProducts,
  getUnauthedReviewProducts,
} from "../../../../../api/graphQl/authenticated/Review";
import { useSignedIn } from "@/utils/hooks/useSignedIn";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import { ReviewPageResponse } from "@/redux/apolloCache";

interface ReviewProductsResponse {
  ReviewProducts: ReviewPageResponse;
}

interface UnauthedReviewProductsResponse {
  UnauthedReviewProducts: ReviewPageResponse;
}

// Use a type that combines both possible responses for type safety
type QueryResponse = Partial<
  ReviewProductsResponse & UnauthedReviewProductsResponse
>;

// Define variables type
interface ReviewQueryVariables {
  brandIds: number[] | undefined;
  page: {
    start: number;
    size: number;
  };
}
export const useFetchReviews = ({
  brandIds,
  pageSize = 25,
  autoInitFetch = true,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const signedIn = useSignedIn().signedIn;
  const query = signedIn ? getReviewProducts : getUnauthedReviewProducts;
  const [initialFetchTriggered, setInitialFetchTriggered] = useState(false);

  // Using useLazyQuery instead of useQuery with skip option
  // This ensures reliable re-rendering when data is fetched with autoInitFetch=false
  const [
    queryFn,
    { data: queryData, loading, error, fetchMore, networkStatus },
  ] = useLazyQuery<QueryResponse, ReviewQueryVariables>(query, {
    variables: {
      brandIds,
      page: {
        start: 0,
        size: pageSize,
      },
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const fetchInit = useCallback(() => {
    if (initialFetchTriggered) {
      return;
    }
    setInitialFetchTriggered(true);
    queryFn();
  }, [queryFn, initialFetchTriggered]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  const handleFetchMore = useCallback(() => {
    if (!queryData) return Promise.resolve();

    // Get the correct data based on the signed-in state
    const responseData = signedIn
      ? queryData.ReviewProducts
      : queryData.UnauthedReviewProducts;

    if (!responseData || !responseData.hasMore) {
      return Promise.resolve();
    }

    // Let Apollo's cache handle merging based on the typePolicies
    return fetchMore({
      variables: {
        page: {
          start: responseData.nextPage?.start || 0,
          size: pageSize,
        },
      },
      // No updateQuery needed - Apollo cache will handle the merge
    });
  }, [fetchMore, queryData, signedIn, pageSize]);

  const responseData = queryData
    ? signedIn
      ? queryData.ReviewProducts
      : queryData.UnauthedReviewProducts
    : null;

  const data = responseData?.items || [];
  const hasMore = responseData?.hasMore || false;
  const loadingMore = loading && networkStatus === NetworkStatus.fetchMore;

  return {
    data,
    loading,
    error: error ? error.message : null,
    hasMore,
    fetchMore: handleFetchMore,
    fetchInit,
    loadingMore,
    initialFetchTriggered,
  };
};
